<template>
	<div>
		<div id="scrollbar">
            <div id="scrollbar-bg"></div>
        </div>
		<header class="header_white">
			<Navbar logoColor="true"></Navbar>
		</header>
		<section id="about" class="bg_lighgrey_pattern">
			<div id="post" class="container">
				<div class="row">
					<div class="col-12 col-lg-10 offset-lg-1 aos-init" data-aos="fade-up" data-aos-duration="1000">
            			<img class="img-fluid img--animated-soft" :src="require(`@/assets/blog/Luka-Jakovljevic-Serbian-Researchers.png`)" alt="blog" height="" width="">
						<h3 class="title_xl title_lightblue mt-4 mb-3">
							Luka Jakovljević: Postoji veliki i neiskorišćen potencijal u umrežavanju srpskih istraživača!
						</h3>

            <div class="text_bold text_m mt-4 mb-4">
              <div class="row px-3">
                <div class="d-inline-block mr-3">
                  <img class="rounded-circle" :src="require(`@/assets/blog/milica.jpeg`)" width="40" height="40">
                </div>
                <div class="d-inline-block">
                  Milica Brković<br>
                  Mar 17, 2025
                </div>
              </div>
            </div>
						<div class="reading-time badge badge-danger d-inline-block px-2">
							<details>
							  <summary>1 minut</summary><span></span>
							</details>
						</div>
            <hr/>
						<p class="text_xl  my-3">
							U eri globalizacije i sve veće mobilnosti naučnika, mogućnost umrežavanja i razmene znanja postaje ključna za razvoj istraživačke zajednice. Međutim, srpski istraživači koji se nalaze u različitim delovima sveta često nemaju jasan mehanizam za međusobno povezivanje, razmenu iskustava i saradnju. Upravo iz te potrebe rodila se ideja za Serbian Researchers – platformu koja ima za cilj da olakša umrežavanje i doprinese većoj vidljivosti srpskih naučnika na globalnom nivou.
							<br><br>
							U drugom intervjuu sa osnivačima platforme, Luka Jakovljević otkriva kako je nastala ova edukativna inicijativa i na koji način treba da doprinese razvoju srpske naučne zajednice.
						</p>
						<h3 class="title_m title_lightblue my-4 my-lg-5">
							1. Kada se osvrnete na trenutak kada ste odlučili da razvijate platformu Serbian Researchers, kako se ova ideja podudarala sa Vašom ličnom i profesionalnom vizijom? Kada ste pomislili da Vaša ideja može da dobije konkretnu realizaciju?
						</h3>
						<p class="text_xl  my-3">
							Ideja za kreiranje platforme Serbian Researchers je nastala tokom trajanja master studija u Francuskoj, kada je trebalo da pronađem obaveznu stručnu praksu u trajanju od jednog semestra. Kao strancu, bez ikakvih kontakata u industriji, bilo mi je izuzetno teško da negde dođem do intervjua. Igrom slučaja, moj CV je nakon jednog sajma poslova došao do Jelene Pešić u Nokia Bell Labs-u, koja me je preporučila za intervju, nakon čega sam dobio praksu.
							<br><br>
							Znajući za postojanje platforme Serbian Entrepreneurs, pomislio sam, koliko bi ceo proces bio lakši, da sam imao način da znam za postojanje naših istraživača u zemlji u koju dolazim. Po uzoru na platformu za srpske preduzetnike, Nemanja, Velimir i ja, započeli smo rad na platformi koja treba da poveže srpske istraživače u svetu.
						</p>

						<h3 class="title_m title_lightblue my-4 my-lg-5">
							2. Šta ste naučili kroz proces rada na platformi?
						</h3>
						<p class="text_xl  my-3">
							Kroz interakciju i povratne informacije koje dobijamo od članova platforme, saznali smo koji su realni problemi sa kojima se suočavaju istraživači kada je u pitanju povezivanje članova. Ostaje nam još mnogo posla i inicijativa na ovom polju, kao što je povećanje vidljivosti članova i organizovanje događaja, gde bi istraživači mogli uživo da se upoznaju i pospeše saradnje.
							<br><br>
							Jedan od prvih sledećih koraka koje planiramo je da platforma posluži kao medijum za obaveštenja o otvorenim pozicijama za prakse, doktorate, grantove i druge vidove saradnje među istraživačima.

						</p>

						<h3 class="title_m title_lightblue my-4 my-lg-5">
							3. Kako bi platforma Serbian Researchers trebalo da promeni način na koji se istraživači povezuju, sarađuju i ostvaruju profesionalne prilike?
						</h3>
						<p class="text_xl  my-3">
							U potrazi smo za pravim načinom da pospešimo interakciju među istraživačima. Kreiranjem javno dostupne i besplatne baze istraživača činimo jedan veliki korak gde postoji pretraga po filterima i ključnim rečima, tako da istraživači lakše mogu pronaći kolege u nekoj oblasti.
							<br><br>
							Međutim, ključ u saradnji bi prvenstveno bio u prenosu znanja, iskustava i informacija mlađim istraživačima, bilo to kroz mentorske sesije, radionice ili zajedničke istraživačke projekte. Jedna od misija platforme je da se omogući prilika mlađim istraživačima da upoznaju naše vrhunske naučnike, profesore i osnivače kompanija kako bi čuli njihove priče o karijernom putu. Takođe, pozitivnu povratnu spregu bi činile uspešne priče istraživača kojima je platforma koristila u profesionalnoj karijeri.
						</p>

						<h3 class="title_m title_lightblue my-4 my-lg-5">
							4. Šta bi bili konkretni benefiti za akademsku zajednicu, a šta za industriju?  	

						</h3>
						<p class="text_xl  my-3">
							U sponi akademske zajednice i industrije, fakulteti imaju koristi od saradnje na realnim problemima, dok kompanije dobijaju pristup najnaprednijem istraživanju. Ovakvih saradnji definitivno treba da bude više u Srbiji.
							<br><br>
							Vidimo nekoliko načina kako se konkretno, a dugoročno, može pospešiti saradnja. Prvi je takozvani industrijski doktorat, gde kompanije stipendiraju studente koji su zajedno mentorisani od strane profesora na fakultetu i istraživača u kompaniji. Drugi je zajedničko finansiranje razvoja patenata zarad kasnije komercijalizacije. Treći način je osnivanje fokusiranih laboratorija koje će određeno vreme rešavati konkretan industrijski problem. Definitivno, sigurni smo da postoji veliki i neiskorišćen potencijal u umrežavanju srpskih istraživača, od kojih benefit mogu imati kako pojedinci, tako i institucije iz kojih dolaze.

						</p>
					</div>

				</div>
			</div>
		</section>
		<Footer bg="footer_bg-red"></Footer>
	</div>
</template>


<script>
import Navbar from "@/components/layout/Navbar"
import Footer from "@/components/layout/Footer"

export default{
	name: "PlatformaSrpskiIstrazivaci",

	components: {
		Navbar,
		Footer
	},
	methods: {
		getWordCount(){
			const post = document.getElementById("post");
			return post.innerText.match(/\w+/g).length;
		},

		setReadingTime(){
			const avgWordsPerMin = 250;
			const readingTimeSummary = document.querySelector(".reading-time");

			let count = this.getWordCount();
			let time = Math.ceil(count / avgWordsPerMin);

			readingTimeSummary.innerText = time + " min čitanja";
		}
	},

  	mounted() {

		this.setReadingTime();

      	var bar_bg = document.getElementById("scrollbar-bg"),
			body = document.body,
			html = document.documentElement;

		bar_bg.style.minWidth = document.width + "px";

		document.getElementsByTagName("body")[0].onresize = function() {
			// Update the gradient width
			bar_bg.style.minWidth = document.width + "px";
		}

		window.onscroll = function() {
			// Change the width of the progress bar
			var bar = document.getElementById("scrollbar"),
				dw  = document.documentElement.clientWidth,
				dh  = Math.max( body.scrollHeight, body.offsetHeight,
							html.clientHeight, html.scrollHeight, html.offsetHeight ),
				wh  = window.innerHeight,
				pos = pageYOffset || (document.documentElement.clientHeight ?
								document.documentElement.scrollTop : document.body.scrollTop),
				bw  = ((pos / (dh - wh)) * 100);

			bar.style.width = bw + "%";
		}
  	}
}

</script>
